export const DestinationContinentsData = [
  {
    name: 'Europe',
    href: '/travel/destinations/europe',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873904/europe_sczvqf.webp',
  },
  {
    name: 'Asia',
    href: '/travel/destinations/asia',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873902/asia_huc6az.webp',
  },
  {
    name: 'North America',
    href: '/travel/destinations/north-america',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873902/north-america_toei64.webp',
  },
  {
    name: 'Central America',
    href: '/travel/destinations/central-america',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873902/central-america_u7cxaa.webp',
  },
  {
    name: 'South America',
    href: '/travel/destinations/south-america',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873907/south-america_pqvmpp.webp',
  },
  {
    name: 'Caribbean',
    href: '/travel/destinations/caribbean',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873900/caribbean_hplxdo.webp',
  },
  {
    name: 'Oceania',
    href: '/travel/destinations/oceania',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873903/oceania_dcobhn.webp',
  },
  {
    name: 'Africa',
    href: '/travel/destinations/africa',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873902/africa_fhqijc.webp',
  },
  {
    name: 'Middle East',
    href: '/travel/destinations/middle-east',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873902/middle-east_oevrmo.webp',
  },
  {
    name: 'Polar',
    href: '/travel/destinations/polar',
    imageSrc:
      'https://media.foratravel.com/image/upload/v1715873904/polar_yrx8in.webp',
  },
]
