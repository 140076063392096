import Image from 'next/image'
import { DestinationContinentsData } from '@/utils/fixtures/DestinationContinentsData'

const DestinationRow = ({ destinations, index }) => {
  return (
    <ul className="flex flex-wrap gap-6 mb-6 md:flex-nowrap" data-name="row">
      {destinations.map((destination) => (
        <DestinationBox
          key={destination.name}
          destination={destination.name}
          href={destination.href}
          imageSrc={destination.imageSrc}
          width={index % 2 ? '3' : '2'}
        />
      ))}
    </ul>
  )
}

export const DestinationBox = ({
  destination,
  imageSrc,
  href,
  width,
  alt = '',
}) => {
  const setWidth = {
    '2': 'md:w-1/2',
    '3': 'md:w-1/3',
    category: 'category',
  }
  const isTwoItemRow = width === '2'
  return (
    <li
      className={`relative w-full cursor-pointer ${setWidth[width]} related-destination-box group`}
    >
      <a href={href}>
        <h2 className="font-sans text-[48px] text-white leading-tight absolute z-20 flex items-center justify-center w-full h-full px-4 text-center">
          {destination}
        </h2>
        <div
          className={`overflow-hidden relative w-full h-[237px] ${
            isTwoItemRow ? 'md:h-[365px]' : 'md:h-[217px]'
          } `}
        >
          <Image
            className="transition duration-500 origin-center transform group-hover:scale-105"
            src={imageSrc}
            alt={alt || destination?.title}
            fill={true}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>
      </a>
    </li>
  )
}

const DestinationContinents = (): JSX.Element => {
  const rows = [
    DestinationContinentsData.slice(0, 2),
    DestinationContinentsData.slice(2, 5),
    DestinationContinentsData.slice(5, 7),
    DestinationContinentsData.slice(7),
  ]

  return (
    <section>
      {rows.map((rowDestinations, index) => (
        <DestinationRow
          key={index}
          destinations={rowDestinations}
          index={index}
        />
      ))}
    </section>
  )
}

export default DestinationContinents
