import { useRef, useState } from 'react'
import Image from 'next/image'
import { imageLoader } from '@/utils/ImageLoaders'
import ForaSwiper from '../swiper/ForaSwiper'
import { SwiperContainer } from 'swiper/element'
import { Swiper } from 'swiper/types'

export const AppPreviewAdvisorData = {
  head: 'Community app for collaboration and support',
  subHead:
    'Forum, our interactive community app, offers our advisors a global network of travel insiders at their fingertips.',
  subCopy: 'Available on mobile and desktop',
  items: [
    {
      title: 'Easily connect with the Fora HQ team and fellow advisors',
      icon: 'https://media.foratravel.com/image/upload/v1715877483/Users_zaic18.svg',
      video:
        'https://media.foratravel.com/video/upload/v1706620322/Forum_-_Home_r6muq4.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/v1706620322/Forum_-_Home_r6muq4.webp',
    },
    {
      title: 'Stay up-to-date on all the latest industry trends',
      icon: 'https://media.foratravel.com/image/upload/v1715948921/CalendarCheck_j5w1ge.svg',
      video:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Destinations_li9ziq.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Destinations_li9ziq.webp',
    },
    {
      title: 'Join weekly webinars with Fora’s partners',
      icon: 'https://media.foratravel.com/image/upload/v1727797551/Eye_s3yw2i.svg',
      video:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Announcements_mqejrs.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Announcements_mqejrs.webp',
    },
    {
      title: 'Access live trip-planning support for any destination',
      icon: 'https://media.foratravel.com/image/upload/v1727798069/Globe_lh8pax.svg',
      video:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Webinars_onqtxl.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/v1706620321/Forum_-_Webinars_onqtxl.webp',
    },
  ],
}

export const AppPreviewForaReserveData = {
  head: 'Our not-so-secret weapon',
  subHead:
    'A purpose-built booking platform that’s a one-stop-shop for advisors to browse and book with unprecedented ease and speed.',
  subCopy: 'Available on mobile and desktop',
  items: [
    {
      title: 'Purpose-built booking platform',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/CalendarCheck_cakodb.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690171/Purpose_Built_Booking_Platform_-_Oct_2024_gonl8r.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690171/Purpose_Built_Booking_Platform_-_Oct_2024_gonl8r.webp',
    },
    {
      title: 'Fora Reserve partners rank first',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/SortDescending_zlxryf.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690169/Fora_Reserve_partners_rank_first_-_Oct_2024_yfeaau.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690169/Fora_Reserve_partners_rank_first_-_Oct_2024_yfeaau.webp',
    },
    {
      title: 'Browse and book rates in real-time',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/Watch_rg7lwo.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690170/Browse_and_book_rates_in_real-time_-_Oct_2024_tacfwe.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690170/Browse_and_book_rates_in_real-time_-_Oct_2024_tacfwe.webp',
    },
  ],
}

export const AppPreviewJoinUsPage = {
  head: 'Our not-so-secret weapon',
  subHead:
    'A purpose-built booking platform that’s a one-stop-shop for advisors to browse and book with unprecedented ease and speed.',
  subCopy: 'Available on mobile and desktop',
  items: [
    {
      title: 'Purpose-built booking platform',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/CalendarCheck_cakodb.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690171/Purpose_Built_Booking_Platform_-_Oct_2024_gonl8r.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690171/Purpose_Built_Booking_Platform_-_Oct_2024_gonl8r.webp',
    },
    {
      title: 'Ability to book 70k+ hotels',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/SortDescending_zlxryf.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690169/Fora_Reserve_partners_rank_first_-_Oct_2024_yfeaau.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690169/Fora_Reserve_partners_rank_first_-_Oct_2024_yfeaau.webp',
    },
    {
      title: 'Browse and book rates in real-time',
      icon: 'https://media.foratravel.com/image/upload/v1718200505/Watch_rg7lwo.svg',
      video:
        'https://media.foratravel.com/video/upload/v1729690170/Browse_and_book_rates_in_real-time_-_Oct_2024_tacfwe.mp4',
      poster:
        'https://media.foratravel.com/video/upload/so_0/f_auto:image,q_auto/v1729690170/Browse_and_book_rates_in_real-time_-_Oct_2024_tacfwe.webp',
    },
  ],
}

const ICON_SIZE = 32

const TabContent = ({ id, activeTab, viderSrc }) => {
  return (
    <div
      className={`absolute w-full h-full top-0 left-0 ${
        activeTab === id ? 'opacity-1' : 'opacity-0'
      }`}
      data-name="desktop-video"
    >
      <video autoPlay muted loop playsInline controls={false}>
        <source src={viderSrc} />
      </video>
    </div>
  )
}

const TabNavItem = ({ id, title, activeTab, setActiveTab, icon = null }) => {
  const handleClick = () => {
    setActiveTab(id)
  }

  return (
    <li
      onClick={handleClick}
      onKeyDown={handleClick}
      className={`flex mb-4 items-center gap-4 font-normal p-4 cursor-pointer border-2 border-darkShell w-[400px] ${
        activeTab === id ? 'bg-darkSand' : ''
      }`}
      role="tab"
    >
      <div className="w-8">
        <Image
          loader={({ src }) =>
            imageLoader({
              src: src,
              width: ICON_SIZE,
              quality: ICON_SIZE,
            })
          }
          src={icon || ''}
          alt="Find Fora Advisor on Instagram"
          layout="responsive"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      </div>

      <h5 className="text-darkStone fora-text-h8">{title}</h5>
    </li>
  )
}

export const AppPreview = ({ head, subHead, items, subCopy }): JSX.Element => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const swiperRef = useRef<SwiperContainer | null>(null)
  const setSwiperRef = (ref: SwiperContainer) => {
    swiperRef.current = ref
  }

  const initVideo = (swiper: Swiper): void => {
    if (swiper.slides.length > 0) {
      const activeSlideIndex = swiper.activeIndex
      const activeSlide = swiper.slides[activeSlideIndex]
      const video = activeSlide.querySelector('video')
      if (video) {
        setTimeout(() => video.play(), 500)
      }
    }
  }

  const pauseAllVideos = (): void => {
    if (swiperRef.current) {
      const activeSlideIndex = swiperRef.current.swiper.activeIndex
      const slides = swiperRef.current.querySelectorAll('swiper-slide')

      slides.forEach((slide, index) => {
        const video = slide.querySelector('video')
        if (video) {
          if (index === activeSlideIndex) {
            video.play()
          } else {
            video.currentTime = 0
            video.pause()
          }
        }
      })
    }
  }

  return (
    <section
      id="app-preview"
      className="pt-12 m-auto mb-4 lg:pb-8 lg:pt-22 lg:max-w-2xl"
    >
      <h2 className="m-auto mb-6 text-center lg:mb-8 fora-text-h3">{head}</h2>
      <h3
        data-name="sub-head"
        className="mt-2 mb-4 text-center text-darkStone lg:mb-14 fora-text-body-1"
      >
        {subHead}
      </h3>
      <div className="hidden gap-12 lg:flex" data-name="desktop-section">
        <div className="place-self-center">
          <ul>
            {items.map((tab, idx) => (
              <TabNavItem
                key={idx}
                title={tab.title}
                id={idx}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                icon={tab.icon}
              />
            ))}
          </ul>
          <p className="fora-text-body-italic-3 text-darkStone">{subCopy}</p>
        </div>
        <div>
          <div className="w-[350px] h-[720px] relative">
            {items.map((tab, idx) => (
              <TabContent
                id={idx}
                key={idx}
                activeTab={activeTab}
                viderSrc={tab.video}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="block lg:hidden" data-name="mobile-section">
        <div className="w-screen">
          <ForaSwiper
            setSwiperRef={setSwiperRef}
            params={{
              on: {
                afterInit(swiper) {
                  initVideo(swiper)
                },
                slideChangeTransitionEnd: pauseAllVideos,
              },
              injectStyles: [
                `
              .swiper-pagination {
                bottom: -5px!important;
              }
              .swiper-wrapper{
                padding-bottom: 35px!important;
              }
              `,
              ],
              lazyPreloadPrevNext: 1,
              slidesOffsetAfter: 50,
              navigation: true,
              pagination: true,
              keyboard: true,
              slidesPerView: 1.25,
              spaceBetween: 16,
              centeredSlides: false,
              breakpoints: {
                768: {
                  slidesPerView: 1.5,
                  centeredSlides: true,
                },
              },
            }}
          >
            {items.map((tab, idx) => (
              <div key={idx}>
                <div
                  className="p-6 pb-7 text-center lg:pb-0 lg:p-0 min-h-[104px]"
                  data-name="slider-content"
                >
                  <div className="m-auto w-8 lg:w-12">
                    <Image
                      loader={({ src }) =>
                        imageLoader({
                          src: src,
                          width: ICON_SIZE,
                          quality: ICON_SIZE,
                        })
                      }
                      src={tab.icon || ''}
                      alt="Find Fora Advisor on Instagram"
                      layout="responsive"
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                    />
                  </div>
                  <h5 className="px-4 text-darkStone fora-text-h5">
                    {tab.title}
                  </h5>
                </div>
                <div
                  className="bg-cover"
                  style={{ backgroundImage: `url(${tab.poster})` }}
                >
                  <video
                    muted
                    loop
                    playsInline
                    controls={false}
                    preload="auto"
                    poster={tab.poster}
                  >
                    <source src={tab.video} type="video/mp4" />
                  </video>
                </div>
              </div>
            ))}
          </ForaSwiper>
        </div>
      </div>
    </section>
  )
}
