export interface Commission {
  title: string
  commission: number
  image: string
  altText: string
  bookingCost?: number
}

export const Commissions: Commission[] = [
  {
    title: 'Your best friend’s hotel staycation',
    commission: 0.1,
    image:
      'https://media.foratravel.com/image/upload/v1708688728/commission-calculator-1_efrukg.jpg',
    altText: 'Furnished room',
    bookingCost: 1000,
  },
  {
    title: 'Your parents’ cruise',
    commission: 0.18,
    image:
      'https://media.foratravel.com/image/upload/v1708688728/commission-calculator-2_ymdizc.jpg',
    altText: 'Beach with cruise',
    bookingCost: 5000,
  },
  {
    title: 'Your colleague’s safari ',
    commission: 0.16,
    image:
      'https://media.foratravel.com/image/upload/v1708688728/commission-calculator-3_iefh7l.jpg',
    altText: 'Giraffe outside vehicle',
    bookingCost: 15000,
  },
  {
    title: 'Your custom adventure',
    commission: 0.12,
    image:
      'https://media.foratravel.com/image/upload/v1708688728/commission-calculator-4_cwzjiy.jpg',
    altText: 'Woman sitting on holiday home in Italy, Borgo Gallana',
  },
]
